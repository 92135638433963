import React from "react";

import { readState } from "@/__main__/app-state.mjs";
import Layout from "@/feature-reskin-probuilds-net/components/Layout.jsx";
import Matchlist from "@/feature-reskin-probuilds-net/components/Matchlist.jsx";
// import { ImgTag } from "@/game-lol/components/ChampionImg.jsx";
// import { CHAMPIONS } from "@/game-lol/constants/champion-classes.mjs";
// import Static from "@/game-lol/utils/static.mjs";
// import useStaticChampionByKey from "@/game-lol/utils/use-static-champion-by-id.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function Champion() {
  const {
    parameters: [championId, role = null],
  } = useRoute();
  const {
    lol: { championProMatches },
  } = useSnapshot(readState);

  const matches = championProMatches?.[championId]?.[role]?.probuildMatches;

  // const championKey = CHAMPIONS[championId]?.key;
  // const champion = useStaticChampionByKey(championKey);

  return (
    <Layout
    // title={champion?.name || championId}
    // image={Static.getChampionImage(championId)}
    // imageClassName={ImgTag()}
    >
      <Matchlist currRole={null} matches={matches} />
    </Layout>
  );
}

export default Champion;

export function meta() {
  return {
    title: [
      "probuildsnet:home.meta.title",
      "ProBuilds - LoL Pro Champion Stats, Teams, Builds, Runes, Masteries, Counters and Matchups!",
    ],
    description: [
      "probuildsnet:home.meta.description",
      "ProBuilds provides League of Legends pro champion statistics, guides, builds, runes, masteries, skill orders and counters by role - including Win Rate, Ban Rate, Play Rate and much more!",
    ],
  };
}
